<template>
  <div v-if="error">{{ error }}</div>
  <div v-else class="w-100 mb-3">
    <table class="table">
      <thead class="table-light">
        <tr>
          <th scope="col">{{ $t("STATUS") }}</th>
          <th scope="col">{{ $t("PROGRESS") }}</th>
          <th scope="col">{{ $t("SCENARIO") }}</th>
          <th scope="col">{{ $t("TEST_DRIVE") }}</th>
          <th scope="col">
            <div class="custom-tooltip tooltip-middle-width">
              {{ $t("PORSCHE_MODEL") }}
              <i
                class="bi bi-info-circle-fill ms-1 custom-tooltip tooltip-middle-width"
              >
                <span class="tooltiptext" style="top: 100%">
                  {{ $t("PORSCHE_MODEL_TOOLTIP_TEXT") }}
                </span></i
              >
            </div>
          </th>
          <th scope="col">
            <div class="custom-tooltip tooltip-middle-width">
              {{ $t("ALLOWED_VEHICLE_TYPE") }}
              <i
                class="bi bi-info-circle-fill ms-1 custom-tooltip tooltip-middle-width"
              >
                <span class="tooltiptext" style="top: 100%">
                  {{ $t("ALLOWED_VEHICLE_TYPE_TOOLTIP_TEXT") }}
                </span></i
              >
            </div>
          </th>
          <th scope="col">
            <div class="custom-tooltip tooltip-middle-width">
              {{ $t("NOT_ALLOWED_VEHICLE_TYPE") }}
              <i
                class="bi bi-info-circle-fill ms-1 custom-tooltip tooltip-middle-width"
              >
                <span class="tooltiptext" style="top: 100%">
                  {{ $t("NOT_ALLOWED_VEHICLE_TYPE_TOOLTIP_TEXT") }}
                </span>
              </i>
            </div>
          </th>
          <th scope="col">{{ $t("CONTACT_WAY") }}</th>
          <th scope="col">
            {{ $t("CONTACT_SPECIFICS") }}
            <i
              class="bi bi-info-circle-fill ms-1 custom-tooltip tooltip-middle-width"
            >
              <span class="tooltiptext">{{
                $t("CONTACT_SPECIFICS_TOOLTIP")
              }}</span>
            </i>
          </th>
          <th
            scope="col"
            class="border-0 bg-none"
            v-if="isMarketOrAgencyUser"
          ></th>
          <th
            scope="col"
            class="border-0 bg-none"
            v-if="isMarketOrAgencyUser"
          ></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="config in configs" :key="config.id">
          <!-- status icon -->
          <td class="custom-tooltip">
            <span
              class="tooltiptext"
              data-ctooltip-position="top"
              v-if="config.status"
              >{{ $t(config.status?.toUpperCase()) }}
            </span>
            <i class="bi bi-gear" v-if="config.status === 'inprogress'"></i>
            <i
              class="bi bi-clock-history"
              v-if="config.status === 'pending'"
            ></i>
            <i class="bi bi-check2" v-if="config.status === 'approved'"></i>
          </td>

          <!-- progress in % -->
          <td class="text">{{ config.progress }}%</td>

          <!-- scenario as select -->
          <td>
            <label class="custom-tooltip">
              <select
                class="form-select border-0"
                data-jp="select-scenario"
                @input="updateScenario($event.target.value, config)"
                :disabled="configurationParametersReadonly(config)"
                :title="isAdminUser ? $t('ONLY_FOR_MARKET_USER') : ''"
              >
                <option v-if="!config.scenarioId" selected disabled>
                  {{ $t("PLEASE_SELECT") }}
                </option>
                <option
                  v-for="scenario in scenarios"
                  :key="scenario.id"
                  :value="scenario.id"
                  :selected="
                    config.scenarioId && config.scenarioId === scenario.id
                  "
                  :disabled="
                    scenarioAndContactCombinationIsNotValid(
                      config.contactWayId,
                      scenario.id
                    )
                  "
                >
                  {{ scenario.title }}
                </option>
              </select>
              <span
                v-if="config.scenarioId"
                class="tooltiptext"
                data-ctooltip-position="top"
                >{{ scenarioNameofConfig(config.scenarioId) }}</span
              >
            </label>
          </td>

          <!-- Test drive as toggle -->
          <td>
            <Switch
              data-jp="on-site-test-drive"
              :initValue="config.testDrive"
              @change="updateTestDrive($event.target.checked, config)"
              :disabled="
                configurationParametersReadonly(config) ||
                config?.scenarioId === 7
              "
              :title="isAdminUser ? $t('ONLY_FOR_MARKET_USER') : ''"
            />
          </td>

          <!-- Model as input -->
          <td>
            <input
              type="text"
              class="form-control border-0"
              placeholder="Please fill in..."
              v-model="config.model"
              @change="updateConfig($event.target.value, config, 'model')"
              :disabled="configurationFieldsReadonly(config)"
              :title="isAdminUser ? $t('ONLY_FOR_MARKET_USER') : ''"
            />
          </td>

          <!-- Allowed type as input -->
          <td>
            <input
              type="text"
              class="form-control border-0"
              placeholder="Please fill in..."
              v-model="config.allowedType"
              @change="updateConfig($event.target.value, config, 'allowedType')"
              :disabled="configurationFieldsReadonly(config)"
              :title="isAdminUser ? $t('ONLY_FOR_MARKET_USER') : ''"
            />
          </td>

          <!-- No allowed type as input -->
          <td>
            <input
              type="text"
              class="form-control border-0"
              placeholder="Please fill in..."
              v-model="config.notAllowedType"
              @change="
                updateConfig($event.target.value, config, 'notAllowedType')
              "
              :disabled="configurationFieldsReadonly(config)"
              :title="isAdminUser ? $t('ONLY_FOR_MARKET_USER') : ''"
            />
          </td>

          <!-- Contact way as select -->
          <td>
            <label class="custom-tooltip">
              <select
                class="form-select border-0"
                data-jp="select-contact-way"
                @change="updateContactWay($event.target.value, config)"
                :disabled="configurationParametersReadonly(config)"
                :title="isAdminUser ? $t('ONLY_FOR_MARKET_USER') : ''"
              >
                <option
                  v-if="
                    !config.contactWayId ||
                    contactWays.some((v) => v.id === config.contactWayId) ===
                      false
                  "
                  selected
                  disabled
                >
                  {{ $t("PLEASE_SELECT") }}
                </option>
                <option
                  v-for="contactWay in contactWays"
                  :key="contactWay.id"
                  :data-id="contactWay.id"
                  :value="contactWay.id"
                  :selected="
                    config.contactWayId && config.contactWayId === contactWay.id
                  "
                  :disabled="
                    scenarioAndContactCombinationIsNotValid(
                      contactWay.id,
                      config.scenarioId
                    )
                  "
                >
                  {{ contactWay.title }}
                </option>
              </select>

              <span class="tooltiptext" data-ctooltip-position="top">{{
                contactWays.find((cw) => cw.id === config.contactWayId)
                  ?.title ?? $t("PLEASE_SELECT")
              }}</span>
            </label>
          </td>

          <!-- Contact specifics as input -->
          <td>
            <input
              type="text"
              class="form-control border-0"
              placeholder="Please fill in..."
              v-model="config.contactSpecifics"
              @change="
                updateConfig($event.target.value, config, 'contactSpecifics')
              "
              :disabled="configurationFieldsReadonly(config)"
              :title="isAdminUser ? $t('ONLY_FOR_MARKET_USER') : ''"
            />
          </td>

          <!-- start, resume, summary Buttons (Market) -->
          <td class="border-0 text-end ps-3" v-if="isMarketOrAgencyUser">
            <OnsiteButton
              v-if="!config.status && !activeWaveReadonly"
              :text="'START'"
              :primary="true"
              :disabled="
                blockStart(config) ||
                configurationLockService.checkLocked(config?.id)
              "
              :runConfig="getStartConfigFunction(config)"
            ></OnsiteButton>

            <OnsiteButton
              v-if="config.status === 'inprogress' && !activeWaveReadonly"
              :text="'CONFIGURATION'"
              :disabled="
                blockStart(config) ||
                configurationLockService.checkLocked(config?.id)
              "
              :runConfig="getRunConfigFunction(config)"
            ></OnsiteButton>

            <OnsiteButton
              v-if="
                config.status === 'pending' ||
                config.status === 'approved' ||
                activeWaveReadonly
              "
              :text="'VIEW'"
              :runConfig="getViewConfigFunction(config)"
              :disabled="blockStart(config)"
            ></OnsiteButton>
          </td>

          <!-- start review, resume review Buttons (Admin) -->
          <td
            style="width: 10rem"
            class="border-0 text-end ps-3"
            v-if="
              isAdminUser &&
              (config.status === 'pending' || config.status === 'approved')
            "
          >
            <OnsiteButton
              v-if="config.status === 'pending' && !activeWaveReadonly"
              :text="'REVIEW'"
              :primary="true"
              :disabled="
                blockStart(config) ||
                configurationLockService.checkLocked(config?.id)
              "
              :runConfig="getRunConfigFunction(config)"
            ></OnsiteButton>

            <OnsiteButton
              v-if="config.status === 'approved' || activeWaveReadonly"
              :text="'VIEW'"
              :runConfig="getViewConfigFunction(config)"
              :disabled="blockStart(config)"
            ></OnsiteButton>
          </td>

          <!-- Reset amd Delete config icons -->
          <td
            v-if="!activeWaveReadonly"
            class="border-0 ps-3"
            data-jp="reset-on-site"
          >
            <div
              class="custom-tooltip tooltip-middle-width"
              v-if="config.status === 'approved'"
            >
              <i class="bi bi-lock-fill" style="color: green" />
              <span class="tooltiptext" style="top: 100%; left: -1000%">{{
                $t("APPROVED_CONFIGURATION")
              }}</span>
            </div>
            <div v-else>
              <div
                v-if="
                  configurationLockService.checkLocked(config?.id) &&
                  ((isAdminUser &&
                    (config.status === 'pending' ||
                      config.status === 'approved')) ||
                    (isMarketOrAgencyUser && config.status === 'inprogress'))
                "
              >
                <div class="custom-tooltip tooltip-middle-width">
                  <i class="bi bi-exclamation-octagon-fill" />
                  <span class="tooltiptext" style="top: 100%; left: -1000%">{{
                    $t("LOCKED_CONFIGURATION")
                  }}</span>
                </div>
              </div>
              <div v-else>
                <div v-if="isMarketOrAgencyUser">
                  <div
                    class="custom-tooltip tooltip-middle-width"
                    v-if="config.status === 'inprogress'"
                  >
                    <i
                      class="bi bi-arrow-repeat"
                      @click="showResetModal(config)"
                    />
                    <span class="tooltiptext" style="top: 100%; left: -1000%">{{
                      $t("RESET_CONFIGURATION")
                    }}</span>
                  </div>
                  <div
                    class="custom-tooltip tooltip-middle-width"
                    v-if="!config.status"
                  >
                    <i class="bi bi-trash" @click="showDeleteModal(config)" />
                    <span class="tooltiptext" style="top: 100%; left: -1000%">{{
                      $t("DELETE_CONFIGURATION")
                    }}</span>
                  </div>
                  <div
                    class="custom-tooltip tooltip-middle-width"
                    v-if="config.status === 'pending'"
                  >
                    <i class="bi bi-lock-fill" style="color: gold" />
                    <span
                      class="tooltiptext tooltip-arrow"
                      style="top: 100%; left: -1000%"
                      >{{ $t("SUBMITTED_CONFIGURATION") }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td class="border-0">
            <div
              v-show="showDuplicateWindow(config)"
              class="w-60 rounded border border-primary"
              role="alert"
            >
              Duplicate config error, update not executed
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <delete-configuration-modal
    v-if="delete_modal"
    v-on:hideDeleteModal="hideDeleteModal"
    v-on:deleteConfig="deleteConfig"
  />

  <reset-configuration-modal
    v-if="reset_modal"
    v-on:hideResetModal="hideResetModal"
    v-on:resetConfiguration="resetConfiguration"
  />
</template>

<script>
import { defineAsyncComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import DeleteConfigurationModal from "@/components/modals/DeleteConfigurationModal";
import OnsiteButton from "./OnSiteButton.vue";
import ResetConfigurationModal from "@/components/modals/ResetConfigurtionModal";
import configurationLockService from "@/services/configurationLockService";
const Switch = defineAsyncComponent(() =>
  import("@/components/switches/Switch")
);

export default {
  name: "OnSiteTable",
  props: ["key"],
  components: {
    OnsiteButton,
    ResetConfigurationModal,
    DeleteConfigurationModal,
    Switch,
  },
  data() {
    return {
      delete_modal: false,
      config_id: null,
      reset_modal: false,
      configurationLockService: configurationLockService,
    };
  },
  async setup(props) {
    const error = ref(null);
    const store = useStore();
    await store.dispatch("configurations/loadOnSiteConfigurations");
    return {
      error,
    };
  },
  created() {
    configurationLockService.connectOnSiteTable(this);
  },
  computed: {
    /**
     * creates an array of ids that have duplication errors
     */
    configs() {
      return this.$store.getters["configurations/getOnSiteConfigurations"];
    },
    contactWays() {
      return this.$store.getters["configurations/getOnSiteContactWays"];
    },
    scenarios() {
      return this.$store.getters["configurations/getScenarios"];
    },
    isAdminUser() {
      return this.$store.getters["users/isAdminUser"];
    },
    isMarketOrAgencyUser() {
      return this.$store.getters["users/isMarketOrAgencyUser"];
    },
    activeWaveReadonly() {
      return this.$store.getters["waves/getActiveWaveReadonly"];
    },
  },
  methods: {
    scenarioAndContactCombinationIsNotValid(contactWayId, scenarioId) {
      return (
        // walk_in - CSV - BEV virtual / UV - ICE virtual / UV - BEV virtual 
        (contactWayId === 14 && 
          ( scenarioId === 7 || 
            scenarioId === 11 || 
            scenarioId === 12)) ||
        // porsche_code - SC - ICE / UV - ICE / SC - BEV / SC - PHEV / UV - BEV / UV - PHEV
        (contactWayId === 15 &&
          ( scenarioId === 1 ||
            scenarioId === 3 ||
            scenarioId === 6 ||
            scenarioId === 8 ||
            scenarioId === 9 ||
            scenarioId === 10)) ||
        // UV - ICE virtual omnichannel only with porsche_code or Contact Form
        (!(contactWayId === 12 || contactWayId === 15 || contactWayId == null) && scenarioId === 13)
      );
    },
    blockStart(config) {
      return (
        !config.contactWayId ||
        !config.scenarioId ||
        this.contactWays.some((v) => v.id === config.contactWayId) === false ||
        this.scenarios.some((v) => v.id === config.scenarioId) === false
      );
    },
    showDuplicateWindow(config) {
      return (
        this.$store.getters[
          "configurations/getDuplicateErrorConfigurationId"
        ] === config.id
      );
    },
    getStartConfigFunction(config) {
      return async () => {
        //Lock configuration
        configurationLockService.lockConfiguration(config.id);

        // start configuration
        await this.$store.dispatch(
          "configurations/startConfiguration",
          config.id
        );

        // reset duplicate configuration window
        this.$store.commit(
          "configurations/setDuplicateErrorConfigurationId",
          -1
        );

        this.$router.push(
          `/projects/${this.$route.params.projectGuid}/waves/${this.$route.params.waveGuid}/configurations/${config.id}`
        );
      };
    },
    getRunConfigFunction(config) {
      return async () => {
        //Lock configuration
        configurationLockService.lockConfiguration(config.id);

        // reset duplicate configuration window
        this.$store.commit(
          "configurations/setDuplicateErrorConfigurationId",
          -1
        );

        this.$router.push(
          `/projects/${this.$route.params.projectGuid}/waves/${this.$route.params.waveGuid}/configurations/${config.id}`
        );
      };
    },
    getViewConfigFunction(config) {
      return async () => {
        // reset duplicate configuration window
        this.$store.commit(
          "configurations/setDuplicateErrorConfigurationId",
          -1
        );

        this.$router.push(
          `/projects/${this.$route.params.projectGuid}/waves/${this.$route.params.waveGuid}/configurations/${config.id}`
        );
      };
    },
    async deleteConfig() {
      await this.$store.dispatch(
        "configurations/deleteConfiguration",
        this.config_id
      );
      this.$store.commit("configurations/setDuplicateErrorConfigurationId", -1);
      this.hideDeleteModal();
    },
    async resetConfiguration() {
      await this.$store.dispatch(
        "configurations/resetConfiguration",
        this.config_id
      );
      this.$store.commit("configurations/setDuplicateErrorConfigurationId", -1);
      this.hideResetModal();
    },
    scenarioNameofConfig(id) {
      const ret = this.scenarios.find((s) => s.id === id);
      if (!ret || !ret.title)
        throw new Error("no matching Scenario for id ", id);
      return ret.title;
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    configurationParametersReadonly(config) {
      return (
        config.status ||
        this.$store.getters["users/isAdminUser"] ||
        this.$store.getters["waves/getActiveWaveReadonly"]
      );
    },
    configurationFieldsReadonly(config) {
      return (
        this.$store.getters["waves/getActiveWaveReadonly"] ||
        config.status === "approved" ||
        (config.status === "pending" &&
          this.$store.getters["users/isAdminUser"] == false) ||
        (config.status !== "pending" &&
          this.$store.getters["users/isMarketUser"] == false)
      );
    },
    async updateContactWay(id, config) {
      if (!this.isNumeric(id)) {
        throw new Error("cant parse to Number:", id);
      }
      let getCurrentContactwayId = config.contactWayId;
      config.contactWayId = Number(id);
      await this.requestConfigSetup(config);
      if (
        this.$store.getters[
          "configurations/getDuplicateErrorConfigurationId"
        ] === config.id
      ) {
        config.contactWayId = getCurrentContactwayId;
        this.$forceUpdate();
      }
    },
    async updateScenario(id, config) {
      if (!this.isNumeric(id)) {
        throw new Error("cant parse to Number:", id);
      }
      let getCurrentScenarioId = config.scenarioId;
      config.scenarioId = Number(id);
      await this.requestConfigSetup(config);
      if (
        this.$store.getters[
          "configurations/getDuplicateErrorConfigurationId"
        ] === config.id
      ) {
        config.scenarioId = getCurrentScenarioId;
        this.$forceUpdate();
      }
    },
    async updateTestDrive(value, config) {
      let currentTestDrive = config.testDrive;
      config.testDrive = value;
      await this.requestConfigSetup(config);
      if (
        this.$store.getters[
          "configurations/getDuplicateErrorConfigurationId"
        ] === config.id
      ) {
        config.testDrive = currentTestDrive;
        this.$forceUpdate();
      }
    },
    async updateConfig(value, config, el) {
      config[el] = value;
      await this.requestConfigUpdate(config);
    },
    async requestConfigSetup(config) {
      await this.$store.dispatch("configurations/setupConfiguration", config);
    },
    async requestConfigUpdate(config) {
      await this.$store.dispatch("configurations/updateConfiguration", config);
    },
    showDeleteModal(config) {
      this.config_id = config.id;
      this.delete_modal = true;
    },
    hideDeleteModal() {
      this.config_id = null;
      this.delete_modal = false;
    },
    showResetModal(config) {
      this.config_id = config.id;
      this.reset_modal = true;
    },
    hideResetModal() {
      this.config_id = null;
      this.reset_modal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
td {
  vertical-align: middle;
  text-align: center;
}
.marker {
  border: 1px solid red;
}
</style>
